import { useState } from 'react'
import { ReactComponent as Burger } from '../../../assets/burger.svg'
import { ReactComponent as Cross } from '../../../assets/cross.svg'
import { ReactComponent as Arrow } from '../../../assets/vector.svg'
import s from './MobileIcons.module.scss'
import { SelectLanguage } from '../SelectLanguage/SelectLanguage'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const MobileIcons = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [subMenu, setSubMenu] = useState(true)
    const { t } = useTranslation()
    return (
        <div>
            <div className={s.burger_icon} onClick={() => setIsOpen(!isOpen)}>
                <Burger />
            </div>
            {isOpen &&
                <div className={s.side_menu}>
                    <div className={s.menu_icons}>
                        <SelectLanguage />
                        <div className={s.cross_icon} onClick={() => setIsOpen(!isOpen)}>
                            <Cross />
                        </div>
                    </div>
                    <div className={s.links}>
                        <div className={s.link_body}>
                            <div className={s.link_title} onClick={() => setSubMenu(!subMenu)}>
                                <span>{t('history')}</span>
                                {subMenu && <Arrow />}
                            </div>
                            {subMenu && <div className={s.nav_links}>
                                <Link to={'/history/date'} onClick={() => setIsOpen(!isOpen)}>
                                {t('date')}
                                </Link>
                                <Link to={'/history/persons'} onClick={() => setIsOpen(!isOpen)}>
                                    {t('person')}
                                </Link>
                                <Link to={'/history/places'} onClick={() => setIsOpen(!isOpen)}>
                                    {t('places')}
                                </Link>
                            </div>}
                        </div>
                        <div className={s.link_body}>
                        <Link to={'/organizations'} className={s.link_title} onClick={() => setIsOpen(!isOpen)}>
                                <span>{t('organization')}</span>
                        </Link>
                        </div>
                        <div className={s.link_body}>
                        <Link to={'/development'} className={s.link_title} onClick={() => setIsOpen(!isOpen)}>
                                <span>{t('development')}</span>
                        </Link>
                        </div>
                    </div>
                </div>}
        </div>
    )
}