import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getDevs = createAsyncThunk(
  'devs/fetchText',
  async (language, {rejectWithValue}) => {

    try {
      const response = await fetch(`https://nasbguide.krokam.by/api/rest/developmentlocales/?lang=${language}`)
      if (!response.ok) {

        throw new Error(response.statusText);
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)


const devsSlice = createSlice({
  name: 'devs',
  initialState: {
    error: null,
    status: null,
    devs: null,
    language: null,
	},
	reducers: {
		setDevLocale: (state, action) => {
		  state.language = action.payload
		},
	  }, 
  extraReducers: (builder) => {
    builder
      .addCase(getDevs.pending, (state) => {
        state.status = 'loading';
        state.error = null
      })
      .addCase(getDevs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.devs = action.payload.sort((a, b) => a.development.pk - b.development.pk)
      })
      .addCase(getDevs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
     
  },
})

export const {
	setDevLocale
  } = devsSlice.actions
export default devsSlice.reducer

