import { useTranslation } from 'react-i18next'
import s from './ButtonSwither.module.scss'
import {ReactComponent as List} from './list.svg'
import {ReactComponent as Map} from './map.svg'
export const ButtonSwitcher = ({buttonActive, setButtonActive}) => {
    const { t } = useTranslation()

    return (
        <div className={s.switcher_container}>
        <div className={s.sectionButtons}>
            <button onClick={() => setButtonActive(true)}
                className={buttonActive ? s.buttonActive : s.buttonPassive}>
                    <List />
                    <span>{t('list')}</span>
            </button>
            <button onClick={() => setButtonActive(false)}
                className={buttonActive ? `${s.buttonPassive} ${s.buttonPassiveMap}` : s.buttonActive}>
                    <Map />
                    <span>{t('map')}</span>
            </button>
        </div>
    </div>
    )
}