import { NavLink, useNavigate } from 'react-router-dom'
import s from './Header.module.scss'
import { SelectLanguage } from './SelectLanguage/SelectLanguage'
import { ReactComponent as Search } from '../../assets/search.svg'
import logo from './logo.png'
import { useTranslation } from 'react-i18next'
import { MobileIcons } from './mobileIcons/MobileIcons'
import { SearchComponent } from '../SearchComponent/SearchComponent'

export const Header = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const navigateToMain = () => {
        navigate('/')
    }
    return (
        <div className={s.container}>
            <div className={s.header}>
                <div onClick={navigateToMain} className={s.logo}>
                    <img src={logo} alt="" />
                </div>
                <div className={s.icons_container}>
                    <NavLink to={'/history'} className={s.link_cont}>
                        <span className={s.first_item}>{t('history')}</span>
                        <span className={s.second_item}>{t('history')}</span>
                    </NavLink>
                    <NavLink to={'/organizations'} className={s.link_cont}>
                        <span className={s.first_item}>{t('organization')}</span>
                        <span className={s.second_item}>{t('organization')}</span>
                    </NavLink>
                    <NavLink to={'/development'} className={s.link_cont}>
                        <span className={s.first_item}>{t('development')}</span>
                        <span className={s.second_item}>{t('development')}</span>
                    </NavLink>
                    <SearchComponent />
                    <SelectLanguage />
                </div>
                <div className={s.mobile_icons_container}>
                    <div className={s.mobile_search}>
                        <SearchComponent />
                    </div>
                    <MobileIcons />
                </div>
            </div>
        </div>
    )
}